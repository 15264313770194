<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-left">{{ ActivityT }}</h1>
              <div class="breadcrumb-bar">
                <ul class="breadcrumb text-left">
                  <li>
                    <router-link href="#" aria-label="title" to="/">{{
                      home
                    }}</router-link>
                  </li>
                  <li>{{ ActivityT }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Activity Details Area Start-->
    <div class="news-details-area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-12 col-12">
            <div class="news-details-content">
              <div class="single-latest-item">
                <img loading="lazy" v-bind:src="imgUniv" alt="" />
                <div class="single-latest-text">
                  <h3 id="TitleUniv"></h3>
                  <p id="DetailsUniv"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-12">
            <div class="sidebar-widget">
              <div class="single-sidebar-widget">
                <h4 class="title">الانشطة</h4>
                <div class="recent-content">
                  <div
                    v-for="Events in Activity"
                    :key="Events.postId"
                    class="recent-content-item"
                  >
                    <router-link
                      href="#"
                      aria-label="title"
                      :to="{
                        name: 'Activity-with-id',
                        params: {
                          id: Events.postID,
                        },
                      }"
                      ><img
                        loading="lazy"
                        alt=""
                        @click="getDataEvents(Events.postID)"
                        class="img_left"
                        v-bind:src="
                          'https://api2.yuniv.net:444/images/post/' +
                          Events.postImage
                        "
                      />
                    </router-link>
                    <div class="recent-text">
                      <p @click="getDataEvents(Events.postID)">
                        <router-link
                          href="#"
                          aria-label="title"
                          :to="{
                            name: 'Activity-with-id',
                            params: {
                              id: Events.postID,
                            },
                          }"
                        >
                          {{ Events.postTitle }}
                        </router-link>
                      </p>
                      <!-- <p>   {{ Events.postSubTitle }}</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="single-sidebar-widget">
                <ul class="tags">
                  <li>
                    <router-link
                      href="#"
                      aria-label="title"
                      :to="{
                        name: 'Events-With-Type',
                        params: {
                          type: 'Univ.Activity',
                        },
                      }"
                    >
                      {{ BtnMore }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Activity Details Area-->

    <!--Activity Images Area Start-->
    <div
      class="gallery-area pt-50 pb-50"
      style="background-color: #f6f6f6"
      v-show="showActivityImage"
    >
      <div class="section-title-wrapper">
        <div class="section-title">
          <h3>{{ ActivityImage }}</h3>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 mb-30"
            v-for="avtivityImage in AvtivityImage"
            :key="avtivityImage.postId"
          >
            <div class="gallery-img">
              <img
                loading="lazy"
                v-bind:src="
                  'https://api2.yuniv.net:444/images/post/' +
                  avtivityImage.postImage
                "
                alt=""
              />
              <div class="hover-effect">
                <div class="zoom-icon">
                  <a
                    class="popup-image"
                    v-bind:href="
                      'https://api2.yuniv.net:444/images/post/' +
                      avtivityImage.postImage
                    "
                    ><i class="fa fa-search-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Activity Images Area-->

    <div></div>
  </div>
</template>
<script>
import Loading from "./Loading";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      Activity: [],
      AvtivityImage: [],
      Title: "",
      Details: "",
      showActivityImage: true,
      imgUniv: "",
      ActivityImage: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      ActivityT: "",
      BtnMore: "",
      id: "",
    };
  },
  methods: {
    getDataActivity(PostID) {
      var self = this;
      self.show = true;
      window.scroll(0, 0);
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
      }
      self.ActivityT = self.translate[0]["Home"]["Activity"];
      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("PostId", PostID);
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("FbrnId", "U");
      bodyFormData1.append("Type", "Univ.Activity");
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          var arr = response.data;
          if (arr.length != 0) {
            self.imgUniv =
              "https://api2.yuniv.net:444/images/post/" +
              response.data[0]["postImage"];
            document.getElementById("TitleUniv").innerText =
              response.data[0]["postTitle"];
            document.getElementById("DetailsUniv").innerHTML =
              response.data[0]["postDetails"];
          }
          self.show = false;
        })
        .catch(function () {
          //   console.log("error", response);
          self.show = false;
        });
    },
    getApis() {
      var self = this;
      self.id = this.$route.params.id;
      self.getDataActivity(this.$route.params.id);

      var bodyFormData2 = new FormData();
      bodyFormData2.append("check", "getPost");
      bodyFormData2.append("Lang", localStorage.getItem("lang"));
      bodyFormData2.append("PostId", "");
      bodyFormData2.append("FbrnId", "U");
      bodyFormData2.append("Type", "Univ.Activity");
      bodyFormData2.append("ParentId", "NTROOT0");
      bodyFormData2.append("PostName", "");
      bodyFormData2.append("Pno", "6");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData2,
      }).then(function (response) {
        self.Activity = response.data;

        var bodyFormData3 = new FormData();
        bodyFormData3.append("check", "getPost");
        bodyFormData3.append("Lang", localStorage.getItem("lang"));
        bodyFormData3.append("PostId", "");
        bodyFormData3.append("FbrnId", "U");
        bodyFormData3.append("Type", "Univ.ActivityGallery");
        bodyFormData3.append("ParentId", self.id);
        bodyFormData3.append("PostName", "");
        bodyFormData3.append("Pno", "-1");
        axios({
          method: "post",
          url: "https://api2.yuniv.net:444/our_team/getPost",
          data: bodyFormData3,
        })
          .then(function (response) {
            var arr = response.data;
            if (arr.length == 0) self.showActivityImage = false;
            else self.showActivityImage = true;
            self.AvtivityImage = response.data;

            document.getElementById("VueMainJsNewTouch")?.remove();
            let recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute("src", "/js/main.js");
            recaptchaScript.id = "VueMainJsNewTouch";
            document.head.appendChild(recaptchaScript);
            setTimeout(() => {
              self.show = false;
              document
                .querySelectorAll("div.loader")
                .forEach(function (element) {
                  element.setAttribute("hidden", true);
                });
            }, 100);
          })
          .catch(function () {
            //   console.log("error", response);
          });
      });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            self.translate = self.ArTranslat;
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function () {
          //   console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
    self.Activity = self.translate[0]["Home"]["Activity"];
    self.home = self.translate[0]["HeaderAndFooter"]["Home"];
    self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
    self.ActivityImage = self.translate[0]["Home"]["ActivityImage"];
  },
};
</script>

<style scoped>
.title {
  width: 100% !important;
  text-align: right !important ;
  background: #fff !important ;
  position: relative !important ;
}
.single-sidebar-widget .tags li a {
  background: #0c3ef7 none repeat scroll 100% 0;
  border: 1px solid #0c3ef7;
  color: #ffffff;
  float: right;
  margin-bottom: 13px;
  margin-left: 6px;
  padding: 11px 35px;
  position: absolute;
}
.img_left {
  height: 70px !important;
  widows: 70px im !important;
}
.recent-text p {
  font-size: 15px;
  line-height: 23px;
}
.single-latest-item img {
  width: 870px;
  height: 435px;
}
.news-details-content .single-latest-text {
  padding: 28px 20px 0px;
  text-align: justify;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
